import React from 'react'
import { Container, Button, Image, Header, Segment } from 'semantic-ui-react'
import { withTranslation, Trans } from 'react-i18next'

import Layout from '../components/Layouts/PageLayout'
import image404 from '../assets/Image_404.svg'

const NotFoundPage = ({ navigate, t }) => {
  return (
    <Layout>
      <div className='content-wrapper'>
        <Container textAlign='center'>
          <Trans i18nKey={'404.lost'}>
            <div>
              <Header as='h1'>You look lost!<br /><span className='tour-red'>Haben Sie sich verfahren?</span></Header>
              <Header as='h3'>Die von Ihnen angeforderte Seite wurde nicht gefunden,<br />zwischenzeitlich entfernt oder hat nie existiert.</Header>
              <Header as='h3' style={{ marginBottom: 10 }}>Versuchen Sie es in diese Richtung:</Header>
            </div>
          </Trans>
          <Button icon labelPosition='left' onClick={() => window.history.back()} inverted color='red'><i className='icon ta-arrow-1-left' />{t('404.back')}</Button>
          <Button icon labelPosition='right' onClick={() => navigate('/')} color='red'>{t('404.home')}<i className='icon ta-arrow-1-right' /></Button>
        </Container>
        <Segment basic style={{ paddingTop: '60px', paddingBottom: '60px' }} className='gradient' inverted>
          <Container>
            <Image src={image404} fluid />
          </Container>
        </Segment>
      </div>
    </Layout>
  )
}

export default withTranslation()(NotFoundPage)
